<template>
  <div class="NovaCotacao">
    <topoInterno
      v-if="passo != 9 || screenPortrait"
      titulo="Vistoria Online"
      @OpenMenuLateral="menuOpen = true"
    ></topoInterno>
    <div v-if="carregando == true" class="carregando">
      <!--img width="100" src="https://bboneapp.s3.amazonaws.com/evo-white.png" /-->
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <div
      v-if="passo != 9 || screenPortrait"
      class="topoForm"
      :style="`color:${temaCor.tituloPrimario}; border-left-color:${temaCor.tituloPrimario}`"
    >
      {{ verificaPasso() }}
    </div>
    <div class="conteudoInterno text-center mt-3 p-3 text-secondary">
      <div
        v-if="mostraErroGeo == true && screenPortrait"
        class="alert alert-danger"
      >
        Atenção!! Habilite o Recurso de Geo Localização
      </div>
      <b-form
        v-if="exibe == true"
        @submit.stop.prevent="onSubmit"
        class="text-left"
      >
        <div v-if="passo == 0">
          <b-modal
            id="modalCpfDuplicado"
            v-model="form.dadosPessoais.cpfDuplicado"
            hide-footer
            centered
            title="Atenção!"
            @hidden="resetFormCpfCnpj"
          >
            Identificamos que o CPF/CNPJ: {{ this.cpf }}, já encontra-se
            cadastrado em nossa base de dados. (Todos os dados dos outros
            registros como propostas e veículos serão unificados no registro).
            Caso seja um outro Cadastro, informe um novo CPF ou CNPJ.

            <b-button
              class="mt-4"
              block
              @click="resetFormCpfCnpj()"
              type="filled"
              variant="info"
              :style="`background-color:${temaCor.botaoFormularios};`"
            >
              Informar outro CPF/CNPJ
            </b-button>

            <b-button
              class="mt-4"
              block
              @click.stop.prevent="aceiteUnificarAssociado"
              style="background-color: #dd830b"
              type="filled"
            >
              Unificar Cadastros
            </b-button>
          </b-modal>
          <div>
            <label>Nome:</label>
            {{ dadosProposta.dadosCliente.associado }}
          </div>
          <div>
            <label>Email:</label>
            {{ dadosProposta.dadosCliente.email }}
          </div>
          <div>
            <label>Telefone:</label>
            {{ dadosProposta.dadosCliente.telefone }}
          </div>
          <div>
            <label>Celular:</label>
            {{ dadosProposta.dadosCliente.celular }}
          </div>
          <hr />
          <b-form-group
            v-if="!dadosProposta.dadosCliente.tipo"
            id="input-group-2"
            label="Tipo de Pessoa"
            label-for="input-2"
          >
            <b-form-select
              v-model="form.dadosPessoais.tipoPessoa"
              :options="tiposPessoas"
              name="Tipo de Pessoa"
              v-validate="{ required: true }"
              :state="validateState('Tipo de Pessoa')"
              :class="validateClass('Tipo de Pessoa')"
              @change="trocaTipoPessoa()"
            ></b-form-select>
          </b-form-group>
          <div
            v-if="
              dadosProposta.dadosCliente.tipo ||
              this.form.dadosPessoais.tipoPessoa
            "
          >
            <b-form-group
              label="Data de Nascimento"
              label-for="input-2"
              v-if="
                dadosProposta.dadosCliente.tipo == 'Pessoa Física' ||
                this.form.dadosPessoais.tipoPessoa == 'Pessoa Física'
              "
            >
              <the-mask
                type="text"
                class="form-control"
                v-model="form.dadosPessoais.dataNascimento"
                v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
                placeholder="Data de Nascimento"
                mask="##/##/####"
                id="dataNascimento"
                name="Data de Nascimento"
                :masked="true"
                :state="validateState('Data de Nascimento')"
                :class="validateClass('Data de Nascimento')"
              ></the-mask>
            </b-form-group>
            <b-form-group :label="placeHolderCpf" label-for="input-2">
              <the-mask
                type="text"
                class="form-control"
                v-model="cpf"
                v-validate="{ required: true }"
                :placeholder="placeHolderCpf"
                :mask="maskCpf"
                id="cpfcnpj"
                :name="placeHolderCpf"
                :masked="true"
                :state="validateState(placeHolderCpf)"
                :class="validateClass(placeHolderCpf)"
              ></the-mask>
            </b-form-group>
            <b-form-group
              label="RG"
              label-for="input-2"
              v-if="
                dadosProposta.dadosCliente.tipo == 'Pessoa Física' ||
                this.form.dadosPessoais.tipoPessoa == 'Pessoa Física'
              "
            >
              <!--the-mask
              type="text"
              class="form-control"
              v-model="form.dadosPessoais.rgIe"
              v-validate="{ required: true }"
              placeholder="RG"
              mask="##.###-###"
              id="rgIe"
              name="RG"
              :masked="true"
              :state="validateState('RG')"
              :class="validateClass('RG')"
              ></the-mask-->
              <b-form-input
                type="number"
                id="input-2"
                v-model="form.dadosPessoais.rgIe"
                placeholder="RG"
                name="RG"
                v-validate="{ required: true }"
                :state="validateState('RG')"
                :class="validateClass('RG')"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Inscrição Estadual" label-for="input-2" v-else>
              <b-form-input
                id="input-2"
                v-model="form.dadosPessoais.rgIe"
                placeholder="Inscrição Estadual"
                name="Inscrição Estadual"
                v-validate="{ required: true }"
                :state="validateState('Inscrição Estadual')"
                :class="validateClass('Inscrição Estadual')"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="
                dadosProposta.dadosCliente.tipo == 'Pessoa Jurídica' ||
                this.form.dadosPessoais.tipoPessoa == 'Pessoa Jurídica'
              "
              id="input-group-2"
              label="Representante Legal"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.dadosPessoais.representanteLegal"
                placeholder="Nome Completo Represente Legal"
                name="Nome Completo Represente Legal"
                v-validate="{ required: true }"
                :state="validateState('Nome Completo Represente Legal')"
                :class="validateClass('Nome Completo Represente Legal')"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="CPF do Representante Legal"
              label-for="input-2"
              v-if="
                dadosProposta.dadosCliente.tipo == 'Pessoa Jurídica' ||
                this.form.dadosPessoais.tipoPessoa == 'Pessoa Jurídica'
              "
            >
              <the-mask
                type="text"
                class="form-control"
                v-model="form.dadosPessoais.cpfRepresentante"
                v-validate="{ required: true }"
                placeholder="Cpf do Representante"
                mask="###.###.###-##"
                id="cpfRepresentante"
                name="Cpf do Representante"
                :masked="true"
                :state="validateState('Cpf do Representante')"
                :class="validateClass('Cpf do Representante')"
              ></the-mask>
            </b-form-group>
          </div>

          <b-form-group label="CNH" label-for="input-2">
            <the-mask
              type="text"
              class="form-control"
              v-model="form.dadosPessoais.cnh"
              placeholder="CNH"
              mask="###########"
              id="cnh"
              name="CNH"
              :masked="true"
              :state="validateState('CNH')"
              :class="validateClass('CNH')"
            ></the-mask>
          </b-form-group>

          <b-form-group label="Data de Validade CHN" label-for="input-2">
            <the-mask
              type="text"
              class="form-control"
              v-model="form.dadosPessoais.dataVencimentoCnh"
              v-validate="{ date_format: 'dd/MM/yyyy' }"
              placeholder="Vencimento da CNH"
              mask="##/##/####"
              id="dataVencimentoCnh"
              name="Vencimento da CNH"
              :masked="true"
              :state="validateState('Vencimento da CNH')"
              :class="validateClass('Vencimento da CNH')"
            ></the-mask>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Tipo de CNH"
            label-for="input-2"
          >
            <b-select
              :options="tipoDeCarteira"
              name="Tipo de CNH"
              id="Tipo"
              v-model="form.dadosPessoais.catCnh"
              :state="validateState('Tipo de CNH')"
              :class="validateClass('Tipo de CNH')"
            ></b-select>
          </b-form-group>
        </div>
        <div v-if="passo == 1">
          <div>
            <div v-if="dadosProposta.dadosCliente.cep">
              <label>Cep:</label>
              {{ dadosProposta.dadosCliente.cep }}
            </div>
            <div v-else>
              <b-form-group>
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="cep"
                  v-validate="{ required: true }"
                  placeholder="CEP"
                  mask="##.###-###"
                  id="Cep"
                  name="Cep"
                  :masked="true"
                  :state="validateState('Cep')"
                  :class="validateClass('Cep')"
                ></the-mask>
              </b-form-group>
            </div>
          </div>
          <div>
            <div v-if="dadosProposta.dadosCliente.endereco">
              <label>Endereço:</label>
              {{ dadosProposta.dadosCliente.endereco }}
            </div>
            <b-form-group v-else label="Endereço" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.endereco.endereco"
                placeholder="Endereço"
                name="Endereço"
                v-validate="{ required: true }"
                :state="validateState('Endereço')"
                :class="validateClass('Endereço')"
              ></b-form-input>
            </b-form-group>
          </div>

          <b-form-group label="Número" label-for="input-2">
            <b-form-input
              type="number"
              id="input-2"
              v-model="form.endereco.numero"
              placeholder="Número"
              name="Número"
              v-validate="{ required: true }"
              :state="validateState('Número')"
              :class="validateClass('Número')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Complemento" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="form.endereco.complemento"
              placeholder="Complemento"
              name="Complemento"
              :state="validateState('Complemento')"
              :class="validateClass('Complemento')"
            ></b-form-input>
          </b-form-group>
          <div>
            <div v-if="dadosProposta.dadosCliente.bairro">
              <label>Bairro:</label>
              {{ dadosProposta.dadosCliente.bairro }}
            </div>
            <b-form-group v-else label="Bairro" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.endereco.bairro"
                placeholder="Bairro"
                name="Bairro"
                v-validate="{ required: true }"
                :state="validateState('Bairro')"
                :class="validateClass('Bairro')"
              ></b-form-input>
            </b-form-group>
          </div>
          <div>
            <label>Cidade:</label>
            {{ dadosProposta.dadosCliente.cidade }}
          </div>
          <div>
            <label>Estado:</label>
            {{ dadosProposta.dadosCliente.estado }}
          </div>
        </div>
        <div v-if="passo == 2">
          <div>
            <label>Placa:</label>
            {{ dadosProposta.dadosVeiculo.placa }}
          </div>
          <div>
            <label>Grupo:</label>
            {{ dadosProposta.nomeGrupo }}
          </div>
          <div>
            <label>Marca:</label>
            {{ dadosProposta.dadosVeiculo.veiculoMarca }}
          </div>
          <div>
            <label>Modelo:</label>
            {{ dadosProposta.dadosVeiculo.veiculoModelo }}
          </div>
          <div>
            <label>Ano:</label>
            {{ dadosProposta.dadosVeiculo.anoFabricacao }}/{{
              dadosProposta.dadosVeiculo.anoModelo
            }}
          </div>

          <div>
            <label>Valor Fipe:</label>
            {{ dadosProposta.dadosVeiculo.valorFipe | currency }}
          </div>
          <div>
            <label>Valor Coberto:</label>
            {{ dadosProposta.dadosVeiculo.valorCobertura | currency }}
          </div>
          <div v-if="dadosProposta.dadosVeiculo.cilindradas">
            <label>Cilindradas:</label>
            {{ dadosProposta.dadosVeiculo.cilindradas }}
          </div>
          <hr />
          <div v-if="dadosProposta.tipoProposta == 'Substituição'">
            <label>Placa que está Entrando:</label>
            <the-mask
              mask="AAA-#XX#"
              style="text-transform: uppercase"
              id="input-2"
              class="form-control"
              :placeholder="dadosProposta.dadosVeiculo.placa"
              name="Placa Substituição"
              :masked="true"
              v-validate="{ required: true }"
              :state="validateState('Placa Substituição')"
              disabled
            />
          </div>
          <b-form-group
            v-if="dadosProposta.tipoProposta == 'Substituição'"
            label="Placa que está saindo"
            label-for="input-2"
            class="mt-2"
          >
            <the-mask
              mask="AAA-#XX#"
              style="text-transform: uppercase"
              id="input-2"
              class="form-control"
              v-model="form.placaSubstituicao"
              placeholder="Placa"
              name="Placa Substituição"
              :masked="true"
              v-validate="{ required: true }"
              :state="validateState('Placa Substituição')"
            />
          </b-form-group>

          <b-modal
            v-model="modalPlacaSubstituicao"
            hide-footer
            centered
            title="Atenção!"
          >
            <div>
              A placa que vai entrar não pode ser igual a que vai sair. Por
              favor, digite novamente a placa do veiculo que será substituído!
            </div>
            <div class="mt-3 text-right">
              <b-button variant="primary" @click="resetPlacaSubstituicao()">
                <i class="fa fa-fw fa-check"></i>
              </b-button>
            </div>
          </b-modal>

          <b-form-group
            label="Ano exercício do CRLV"
            label-for="anoExercicioCRLV"
            v-if="dadosProposta.somenteAgregado === false"
            key="anoExercicioCRLV"
          >
            <the-mask
              v-if="dadosProposta.somenteAgregado === false"
              type="text"
              class="form-control"
              v-model="form.dadosVeiculo.anoExercicioDut"
              v-validate="{
                required: true,
                date_format: 'yyyy',
                max_value: new Date().getFullYear(),
              }"
              placeholder="Ano exercicio CRLV"
              mask="####"
              id="anoExercicioCRLV"
              name="Ano exercicio CRLV"
              :state="validateState('Ano exercício CRLV')"
              :class="validateClass('Ano exercício CRLV')"
            ></the-mask>
          </b-form-group>
          <b-form-group
            label="Quantidade de Portas"
            label-for="input-2"
            v-if="
              dadosProposta.dadosVeiculo.codTipoVeiculo != 2 &&
              dadosProposta.somenteAgregado === false
            "
          >
            <b-form-input
              type="number"
              id="input-2"
              v-model="form.dadosVeiculo.qtdPortas"
              placeholder="Quantidade de Portas"
              name="Portas"
              v-validate="{ required: true, max_value: 5, min_value: 2 }"
              :state="validateState('Portas')"
              :class="validateClass('Portas')"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="dadosProposta.somenteAgregado === false"
            label="Cor"
            label-for="input-2"
          >
            <b-form-input
              v-if="listaCores.length <= 1"
              id="input-2"
              v-model="form.dadosVeiculo.cor"
              placeholder="Cor"
              name="Cor"
              :state="validateState('Cor')"
              :class="validateClass('Cor')"
            ></b-form-input>
            <v-select
              v-else
              :options="listaCores"
              name="Cor"
              id="Tipo"
              v-model="form.dadosVeiculo.cor"
              v-validate="{ required: true }"
              :state="validateState('Cor')"
              :class="validateClass('Cor')"
            ></v-select>
          </b-form-group>
          <b-form-group
            v-if="dadosProposta.somenteAgregado === false"
            id="input-group-2"
            label="Combustível"
            label-for="input-2"
          >
            <v-select
              v-if="listaCombustiveis.length <= 1"
              :options="tiposCombustivel"
              name="Combustível"
              id="Tipo"
              v-model="form.dadosVeiculo.combustivel"
              v-validate="{ required: true }"
              :state="validateState('Combustível')"
              :class="validateClass('Combustível')"
            ></v-select>
            <v-select
              v-else
              :options="listaCombustiveis"
              name="Combustível"
              id="Tipo"
              v-model="form.dadosVeiculo.combustivel"
              v-validate="{ required: true }"
              :state="validateState('Combustível')"
              :class="validateClass('Combustível')"
            ></v-select>
          </b-form-group>
          <b-form-group
            v-if="dadosProposta.somenteAgregado === false"
            label="quilometragem"
            label-for="quilometragem"
          >
            <b-form-input
              type="number"
              id="quilometragem"
              v-model="form.dadosVeiculo.km"
              placeholder="Km"
              name="quilometragem"
              maxlength="10"
              v-validate="{ required: true, max: 10 }"
              :state="validateState('quilometragem')"
              :class="validateClass('quilometragem')"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="dadosProposta.somenteAgregado === false"
            label="Renavam"
            label-for="input-2"
          >
            <!--the-mask
              type="text"
              id="input-2"
              class="form-control"
              v-model="form.dadosVeiculo.renavam"
              placeholder="Renavam"
              name="Renavam"
              mask="###########"
              :masked="true"
              v-validate="{ required: true, length:11}"
              :state="validateState('Renavam')"
              :class="validateClass('Renavam')"
            ></the-mask-->
            <b-form-input
              type="number"
              id="input-2"
              v-model="form.dadosVeiculo.renavam"
              placeholder="Renavam"
              name="Renavam"
              v-validate="{ required: true }"
              :state="validateState('Renavam')"
              :class="validateClass('Renavam')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Chassi" label-for="input-2">
            <b-form-input
              id="input-2"
              style="text-transform: uppercase"
              v-model="form.dadosVeiculo.chassi"
              placeholder="Chassi"
              name="Chassi"
              maxlength="17"
              v-validate="{ required: true, length: 17 }"
              :state="validateState('Chassi')"
              :class="validateClass('Chassi')"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="dadosProposta.somenteAgregado === false"
            label="Quantidade de Passageiros"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              style="text-transform: uppercase"
              v-model="form.dadosVeiculo.quantidadePassageiro"
              placeholder="Quantidade de Passageiros"
              name="Quantidade de Passageiros"
              maxlength="3"
              v-validate="{ required: true }"
              :state="validateState('Quantidade de Passageiros')"
              :class="validateClass('Quantidade de Passageiros')"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Estado dos Pneus"
            label-for="input-2"
          >
            <v-select
              :options="estadoPneus"
              name="Estado dos Pneus"
              id="Tipo"
              placeholder="Selecionar"
              v-model="form.dadosVeiculo.estadoPneu"
              v-validate="{ required: true }"
              :state="validateState('Estado dos Pneus')"
              :class="validateClass('Estado dos Pneus')"
            ></v-select>
          </b-form-group>
          <b-form-group
            v-if="dadosProposta.somenteAgregado === false"
            label="Tipo de Câmbio"
          >
            <v-select
              :options="tipoCambioOpcoes"
              name="Tipo de Câmbio"
              id="tipoCambio"
              placeholder="Selecionar"
              v-model="form.dadosVeiculo.tipoCambio"
              v-validate="{ required: true }"
              :state="validateState('Tipo de Câmbio')"
              :class="validateClass('Tipo de Câmbio')"
            ></v-select>
          </b-form-group>
          <b-form-group
            v-if="dadosProposta.somenteAgregado === false"
            label="Número do motor"
          >
            <b-form-input
              id="numeroMotor"
              style="text-transform: uppercase"
              v-model="form.dadosVeiculo.numeroMotor"
              placeholder="Número do Motor"
              name="Número do Motor"
              v-validate="{ required: true, alpha_num: true }"
              :state="validateState('Número do Motor')"
              :class="validateClass('Número do Motor')"
            ></b-form-input>
          </b-form-group>

          <b-card
            title="Implementos"
            class="my-3"
            v-if="dadosProposta.implemento"
          >
            <b-list-group>
              <b-list-group-item
                v-for="(item, index) in dadosProposta.implementos"
                :key="item.id"
              >
                {{ item.descricao }} - {{ item.marca }} {{ item.modelo }}
                <b-badge variant="success" class="mr-3">{{
                  item.valorCobertura | currency
                }}</b-badge>

                <b-badge
                  v-if="validaDadoCompleto(item, 'Implemento')"
                  variant="danger"
                  class="mr-3"
                  >Incompleto</b-badge
                >

                <div class="mt-2 text-right">
                  <button
                    class="btn btn-dark btn-block"
                    type="button"
                    id="button-addon2"
                    @click="
                      atualizarComplemento(item, index),
                        (tipoConfiguracaoCaminhao = 'implemento')
                    "
                  >
                    <i class="fa fa-edit"></i>
                    {{
                      validaDadoCompleto(item, 'Implemento')
                        ? 'Completar Dados'
                        : 'Alterar Dados'
                    }}
                  </button>
                </div>
              </b-list-group-item>
            </b-list-group></b-card
          >

          <b-card title="Agregados" class="my-3" v-if="dadosProposta.agregado">
            <b-list-group>
              <b-list-group-item
                v-for="(item, index) in dadosProposta.agregados"
                :key="item.id"
              >
                {{ item.placa || item.chassi }} - {{ item.marca }}
                {{ item.modelo }}
                <b-badge variant="success" class="mr-3">{{
                  item.valorCobertura | currency
                }}</b-badge>

                <b-badge
                  v-if="validaDadoCompleto(item, 'Agregado')"
                  variant="danger"
                  class="mr-3"
                  >Incompleto</b-badge
                >

                <div class="mt-2 text-right">
                  <button
                    class="btn btn-dark btn-block"
                    type="button"
                    id="button-addon2"
                    @click="
                      atualizarComplemento(item, index),
                        (tipoConfiguracaoCaminhao = 'agregado')
                    "
                  >
                    <i class="fa fa-edit"></i>
                    {{
                      validaDadoCompleto(item, 'Implemento')
                        ? 'Completar Dados'
                        : 'Alterar Dados'
                    }}
                  </button>
                </div>
              </b-list-group-item>
            </b-list-group></b-card
          >

          <!-- <b-modal
            v-model="modalImplemento"
            hide-footer
            centered
            title="Dados do Implemento"
          >
            <FormImplemento
              :temaCor="temaCor"
              :formCompleto="true"
              :escondeCobertura="true"
              :idImplemento="implementoToEdit.idPropostaImplemento"
              :dados="implementoToEdit"
              :contModal="contModal"
              @concluido="atualizaImplementoArray"
            ></FormImplemento>
          </b-modal> -->
          <b-modal
            v-model="modalComplemento"
            hide-footer
            centered
            :title="`Dados do ${tipoConfiguracaoCaminhao}`"
          >
            <FormComplemento
              :temaCor="temaCor"
              :formCompleto="true"
              :escondeCobertura="true"
              :desabilitarEdicaoGrupo="true"
              :idComplemento="complementoParaEditar.idPropostaComplemento"
              :tipoComplemento="tipoConfiguracaoCaminhao"
              :dados="complementoParaEditar"
              :contModal="contModal"
              @concluido="atualizarComplementoArray"
            ></FormComplemento>
          </b-modal>
        </div>
        <div v-if="passo == 3" class="acessorios">
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="form.acessorios"
            name="flavour-2"
          >
            <b-form-checkbox
              v-for="item in listaAcessorios"
              :key="item.id_acessorio"
              class="w-100 mb-3"
              :value="item.id_acessorio"
              size="lg"
            >
              <b class="mr-2">{{ item.nomeAcessorio }}</b>
            </b-form-checkbox>
          </b-form-checkbox-group>
          <div v-if="form.acessorios.length > 0">
            <b-form-group label="Defeitos" label-for="input-2">
              <b-form-textarea
                id="input-2"
                v-model="form.detalhesAcessorios"
                placeholder="Caso algum dos acessórios possua defeitos, descreva-os"
                name="Defeitos"
                :state="validateState('Defeitos')"
                :class="validateClass('Defeitos')"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>

        <div v-if="passo == 4">
          <h4>Selecione a Forma de Pagamento:</h4>
          <b-form-group
            v-for="item in listaProdutosMensais"
            id="input-group-2"
            :label="item.nomeProduto"
            label-for="input-2"
            :key="item.id"
          >
            <b-form-select
              v-model="item.valor"
              :options="item.formas"
              name="Tipo"
              :disabled="financeiroBlock"
              @change="verificaTipoPagamento()"
            ></b-form-select>
          </b-form-group>

          <!-- START Escolhe data de vencimento da mensalidade caso boleto -->
          <b-form-group
            v-if="
              show === true &&
              utils.verificaEmpresaBemProtege(this.dadosProposta.idEmpresa)
            "
            :label="'Dia de Vencimento da Mensalidade'"
            label-for="input-2"
          >
            <b-form-datepicker
              v-model="form.diaVencimentoMensalidade"
              locale="pt-BR"
              label-no-date-selected="Selecionar uma data"
              name="Dia de Vencimento da Mensalidade"
              :date-disabled-fn="desabilitaDatasMensalidade"
              :min="dataMinimaPagamentoMensalidade"
              :max="dataMaximaPagamentoMensalidade"
              v-validate="{ required: true }"
            />
          </b-form-group>

          <b-form-group
            v-if="
              show === true &&
              !utils.verificaEmpresaBemProtege(this.dadosProposta.idEmpresa)
            "
            :label="'Dia de Vencimento da Mensalidade'"
            label-for="input-2"
          >
            <b-form-select
              v-model="form.diaVencimentoMensalidade"
              :options="diasVencimento"
              name="Dia de Vencimento da Mensalidade"
              v-validate="{ required: true }"
            ></b-form-select>
          </b-form-group>

          <!-- END Escolhe data de vencimento da mensalidade caso boleto -->

          <b-form-group
            v-for="item in listaProdutosAAvista"
            id="input-group-2"
            :label="item.nomeProduto"
            label-for="input-2"
            :key="item.id"
          >
            <b-form-select
              v-model="item.valor"
              :options="item.formas"
              name="Tipo"
              :disabled="financeiroBlock"
              @change="verificaTipoPagamento()"
            ></b-form-select>
          </b-form-group>

          <!-- START Escolhe data de vencimento da adesão caso boleto -->
          <b-form-group
            v-if="isBoletoAVista && show === true"
            :label="'Data de vencimento do boleto'"
            label-for="input-2"
          >
            <b-form-datepicker
              v-model="form.dataVencimentoAvista"
              locale="pt-BR"
              label-no-date-selected="Selecionar uma data"
              name="Data de Vencimento da Adesão"
              :date-disabled-fn="desabilitaDatas"
              :min="dataMinimaPagamento"
              :max="dataMaximaPagamento"
              v-validate="{ required: true }"
            />
          </b-form-group>
          <!-- END Escolhe data de vencimento da adesão caso boleto -->

          <div class="mt-3 alert alert-warning" v-if="financeiroBlock">
            Atenção! Não é possível alterar as formas de Pagamento pois as
            cobranças já foram emitidas. Em caso de necessidade, entrem em
            contato com a Associação
          </div>
          <div v-if="hasCartaoCredito">
            <b-button variant="success" @click="modalCreditCard = true"
              >+ Adicionar Cartão</b-button
            >
            <div v-if="listCartoes.length == 0" class="mt-3 alert alert-danger">
              Nenhum Cartão Encontrado
            </div>
            <div v-else class="mt-3 alert alert-info">
              Selecione um dos Cartões para continuar o Processo
            </div>
            <b-list-group>
              <b-list-group-item
                v-for="item in listCartoes"
                :key="item.id"
                :variant="`${item.selecionado == true ? 'success' : ''}`"
              >
                <b-row @click="selecionaCartao(item)">
                  <b-col>
                    {{ item.cardNumber }}
                    <b-badge variant="primary">{{ item.bandeira }}</b-badge>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>

        <div v-if="passo == 5">
          <div class="bg-gray container">
            <div
              v-html="contratoVistoria.modeloContrato"
              style="text-align: justify"
            ></div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.termos[0].termo"
              name="Termo de Vistoria"
              :value="contratoVistoria"
              :unchecked-value="null"
              v-validate="{ required: true }"
              :state="validateState('Termo de Vistoria')"
              >Li e Aceito os Termos</b-form-checkbox
            >
          </div>
        </div>

        <div v-if="passo == 6">
          <div class="bg-gray container">
            <div
              v-html="contratoAdesao.modeloContrato"
              style="text-align: justify"
            ></div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.termos[1].termo"
              name="Termo de Adesão"
              :value="contratoAdesao"
              :unchecked-value="null"
              v-validate="{ required: true }"
              :state="validateState('Termo de Adesão')"
              >Li e Aceito os Termos</b-form-checkbox
            >
          </div>
        </div>

        <div v-if="passo == 7">
          <div class="bg-gray container">
            <div
              v-html="contratoRastreador.modeloContrato"
              style="text-align: justify"
            ></div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.termos[2].termo"
              name="Termo de Rastreador"
              :value="contratoRastreador"
              :unchecked-value="null"
              v-validate="{ required: true }"
              :state="validateState('Termo de Rastreador')"
              >Li e Aceito os Termos</b-form-checkbox
            >
          </div>
        </div>
        <div v-if="passo == 8">
          <div v-if="listaFotos.length > 0" class="img">
            <h3 class="text-center m-3">
              {{ listaFotos[fotoAtual].nomePosicao }}
            </h3>
            <div
              v-if="necessitaUpload == false && tipoFoto == 'gif'"
              class="alert alert-success"
            >
              Você já possui um video importado. Pode confirmar sem selecionar
              um novo ou clique na câmera para trocar!
            </div>
            <div
              v-if="necessitaUpload == false && tipoFoto != 'gif'"
              class="alert alert-success"
            >
              Você já possui uma foto importada. Pode confirmar sem selecionar
              uma nova ou clique na câmera para trocar!
            </div>
            <p v-else class="text-center">{{ textoFoto }}:</p>
            <div
              v-if="listaFotos[fotoAtual].legenda"
              class="alert alert-warning legenda"
            >
              {{ listaFotos[fotoAtual].legenda }}
            </div>

            <div v-if="tipoFoto == 'gif'" class="containerVideo">
              <div
                v-if="listaFotos[fotoAtual].aprovada == false"
                class="text-center alert alert-danger"
              >
                <b>Foto Reprovada:</b>
                {{ listaFotos[fotoAtual].motivoReprovacao }}
                <Br />
                <img
                  class="imgReprovada mt-2"
                  :src="listaFotos[fotoAtual].nome"
                />
                <Br />
                <p class="mt-2">Refaça a foto como o exemplo abaixo:</p>
              </div>

              <img
                v-if="imgUrl && verificaExtensaoVideo() && nomeArquivo == false"
                :src="imgUrl"
              />

              <video
                controls
                muted
                loop
                v-else-if="
                  imgUrl && verificaExtensaoVideo() && nomeArquivo != false
                "
                :src="imgUrl"
              ></video>

              <div class="arquivo" v-else>
                <div v-if="verificaExtensaoVideo()">
                  <video muted>
                    <source :src="imgUrl" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>

            <div v-else class="containerimg">
              <div
                v-if="listaFotos[fotoAtual].aprovada == false"
                class="text-center alert alert-danger"
              >
                <b>Foto Reprovada:</b>
                {{ listaFotos[fotoAtual].motivoReprovacao }}
                <Br />
                <img
                  class="imgReprovada mt-2"
                  :src="listaFotos[fotoAtual].nome"
                />
                <Br />
                <p class="mt-2">Refaça a foto como o exemplo abaixo:</p>
              </div>
              <img
                v-if="imgUrl && verificaExtensaoImagem() && !nomeArquivo"
                :src="imgUrl"
              />
              <div class="arquivo" v-else>
                <div v-if="verificaExtensaoImagem()">
                  <img :src="imgUrl" />
                </div>
                <div>
                  <video
                    v-if="verificaExtensaoVideo()"
                    controls
                    muted
                    loop
                    :src="imgUrl"
                  ></video>

                  <div v-else>
                    <font-awesome-icon class="iconeFile" icon="file-alt" />
                    <span>{{
                      nomeArquivo ? nomeArquivo : formataUrl(imgUrl)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-4"
              v-if="listaFotos[fotoAtual].tipoFoto === 'Vistoria'"
            >
              <b-form-group label="Observações" label-for="input-2">
                <b-form-textarea
                  id="input-2"
                  v-model="form.observacaoVistoria"
                  placeholder=""
                  name="Observação"
                  :state="validateState('Observação')"
                  :class="validateClass('Observação')"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div>
              <b-row v-if="tipoFoto == 'gif' || tipoFoto == 'mp4'">
                <b-col class="text-center"
                  ><botaoVideo @FotoTirada="selecionaArquivoVideo"></botaoVideo
                ></b-col>
              </b-row>

              <b-row v-else>
                <b-col class="text-center"
                  ><botaoCamera
                    @FotoTirada="selecionaArquivoGaleria"
                  ></botaoCamera
                ></b-col>
                <b-col
                  class="text-center"
                  v-if="
                    habilitarFotoGaleria == true ||
                    listaFotos[fotoAtual].tipoFoto == 'Documentação'
                  "
                  ><botaoGaleria
                    @FotoTirada="selecionaArquivoGaleria"
                  ></botaoGaleria
                ></b-col>
              </b-row>
            </div>
            <!--divv-if="isIOSDevice()" v-else>
              <camera
                v-if="habilitaCamera == true"
                @FotoTirada="setImageNew"
                @FecharCamera="habilitaCamera = false"
              />
              <label for="fileInput" slot="upload-label">
                <b-row>
                  <b-col
                    ><div class="containericone" @click="habilitaCamera = true">
                      <font-awesome-icon
                        class="icone"
                        :icon="['fa', iconeCamera]"
                      /></div
                  ></b-col>
                  <b-col v-if="habilitarFotoGaleria == true"
                    ><botaoGaleria
                      @FotoTirada="selecionaArquivoGaleria"
                    ></botaoGaleria
                  ></b-col>
                </b-row>
              </label>
            </div-->
            <p class="text-center mt-3">
              {{
                hasImage
                  ? 'Clique no Icone para Alterar'
                  : habilitarFotoGaleria == true ||
                    listaFotos[fotoAtual].tipoFoto == 'Documentação'
                  ? 'Clique no icone para ter acesso a Camera ou Galeria'
                  : 'Clique no icone para ter acesso a Camera'
              }}
            </p>
            <!--VideoCapture uploadUrl="<example-server-address.com>" v-model="videoUrl" /-->
          </div>
        </div>
        <div v-if="passo == 9">
          <div class="assinatura">
            <div v-if="form.assinatura">
              <img :src="form.assinatura" width="100%" />
            </div>
            <div v-else>
              <div v-if="assinaturaParcial">
                <img :src="this.assinaturaParcial" height="100%" width="100%" />
              </div>
              <div v-else>
                <div
                  v-if="!exibeCanvaAssinatura"
                  class="rotated"
                  :style="heightAssinatura"
                >
                  <div class="iconeGirarTela">
                    <i class="fa fa-mobile fa-4x" aria-hidden="true"></i>
                  </div>
                  <div>
                    <div class="guiaAssinatura">
                      {{ this.dadosProposta.dadosCliente.associado }}
                    </div>
                  </div>
                </div>
                <div v-else :style="heightAssinatura">
                  <div class="guiaAssinatura">
                    {{ this.dadosProposta.dadosCliente.associado }}
                  </div>
                  <VueSignaturePad ref="signaturePad" />
                </div>
              </div>
              <b-button
                v-if="!(screenPortrait && !assinaturaParcial)"
                class="botaoLimpar"
                @click="limparAssinatura()"
              >
                <i class="fa fa-fw fa-eraser"></i> Limpar
              </b-button>
            </div>
          </div>
          <p
            v-if="!form.assinatura && screenPortrait && !verificaAssinatura()"
            class="text-center mt-1"
          >
            Gire o dispositivo para fazer a Assinatura, se não funcionar, por
            favor verifique se a Rotação de Tela está Ativa!
          </p>
        </div>

        <div v-if="passo == 10">
          <div class="text-center">
            <font-awesome-icon :icon="['fa', 'check']" class="iconeSucesso" />
          </div>
          <h4 class="text-uppercase my-5">Parabéns</h4>
          <p>
            Chegamos ao fim do nosso processo de vistoria. Sua solicitação foi
            direcionada para o setor responsável e passará por análise antes de
            aprovação.
          </p>
          <p>Sendo assim, em até 48 horas você receberá nosso feedback</p>
        </div>
        <b-row
          :class="`${
            passo == 9 && !screenPortrait
              ? 'botaoFinalizarAssinatura mt-5'
              : 'botoesfinalizar mt-5'
          }`"
        >
          <b-col v-if="passo != 0 && passo != 10" cols="2">
            <b-button
              v-if="passo != 9 || screenPortrait"
              variant="link text-dark"
              @click="voltar"
            >
              <font-awesome-icon :icon="['fa', 'arrow-left']" />
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-if="passo != 10"
              pill
              :disabled="btnDisabled"
              @click.stop.prevent="onSubmit()"
              ref="btnAvancar"
              size="lg"
              type="submit"
              variant="info"
              class="btn btn-block"
              :style="`background-color:${temaCor.botaoFormularios};`"
              >Confirmar</b-button
            >
            <b-button
              v-else
              pill
              ref="btnAvancar"
              size="lg"
              type="submit"
              variant="info"
              class="btn btn-block"
              :style="`background-color:${temaCor.botaoFormularios};`"
              >Concluir</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-modal
      v-model="modalCreditCard"
      hide-footer
      centered
      title="Dados do Cartão"
      ><b-form class="text-left">
        <cartaoCredito
          @adicionar="adicionarCartaoLista"
          :idProspect="form.idProspect"
          :typeCard="isCartaoMensal ? 'isCartaoMensal' : 'isCartaoAvista'"
        ></cartaoCredito>
      </b-form>
    </b-modal>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import locale from './../locale';
import service from '../services';
import utils from '../services/utils';
import topoInterno from './TopoInterno';
import vSelect from 'vue-select';

import canvasToBlob from 'blueimp-canvas-to-blob';
import moment from 'moment';
import cpfCnpj from './../services/cpfCnpj';
import colors from './../services/colors';
import MenuLateral from './MenuLateral';
import cartaoCredito from './CartaoCredito.vue';
import botaoCamera from './botaoCamera.vue';
import botaoGaleria from './botaoGaleria.vue';
import botaoVideo from './botaoVideo.vue';
import FormComplemento from './FormComplemento.vue';

export default {
  name: 'AceitaCotacao',
  props: {
    msg: String,
  },

  watch: {
    screenPortrait: function () {
      if (this.screenPortrait == true) {
        this.heightAssinatura.background = 'rgba(0, 0, 0, 0.2)';
        if (this.verificaAssinatura()) {
          this.assinaturaParcial = this.$refs.signaturePad.saveSignature().data;
        }
        this.exibeCanvaAssinatura = false;
      } else {
        this.heightAssinatura.background = 'none';
        //this.heightAssinatura.rotate = '0deg';
        this.exibeCanvaAssinatura = true;
        if (this.assinaturaParcial) {
          let assinatura = JSON.parse(JSON.stringify(this.assinaturaParcial));
          this.assinaturaParcial = null;

          setTimeout(() => {
            this.$refs.signaturePad.fromDataURL(assinatura);
          }, 10);
        }
        //this.$refs.signaturePad.openSignaturePad();
      }
    },
    passo: function (newValue, oldValue) {
      if (newValue == 5 || newValue == 6 || newValue == 7) {
        this.scrollToElement();
      }
      if (newValue == 9) this.inicializaAssinatura();
    },
    cep: function (newValue, oldValue) {
      if (this.cep)
        if (this.cep.length == 10) {
          this.buscarCep();
        }
    },
    'form.dadosVeiculo.chassi': function (newValue) {
      if (newValue.length === 17 && this.verificaEmpresa()) {
        this.passo == 2 && this.verificarGrupo();
        this.passo == 3 && this.verificaChassi();
      }
    },
    cpf() {
      if (this.cpf) {
        if (
          (this.cpf.length == 14 &&
            this.form.dadosPessoais.tipoPessoa == 'Pessoa Física') ||
          this.cpf.length == 18
        ) {
          this.form.dadosPessoais.cpfcnpj = this.cpf;
          this.validaCpfUnificacao();
        }
      }
    },
  },

  destroyed() {
    window.removeEventListener('resize', this.getSize);
  },
  data() {
    return {
      utils: utils,
      permitirAcessorios: null,
      screenPortrait: true,
      assinaturaParcial: null,
      exibeCanvaAssinatura: false,
      tipoConfiguracaoCaminhao: null,
      contModal: 0,
      modalImplemento: false,
      modalComplemento: false,
      habilitaCamera: false,
      complementoParaEditar: {},
      indexComplementoParaEditar: null,
      implementoToEdit: {},
      implementoToEditIndex: null,
      nomeArquivo: null,
      decoded: null,
      temaCor: {},
      menuOpen: false,
      tipoFoto: null,
      mostraErroGeo: false,
      tiposPessoas: [
        { text: 'Selecione', value: null },
        'Pessoa Física',
        'Pessoa Jurídica',
      ],
      enderecoImgPadrao: 'https://bboneapp.s3.amazonaws.com/imgsPadrao/',
      videoUrl: null,
      heightAssinatura: {
        height: '387px',
        background: 'rgba(0, 0, 0, 0.2)',
      },
      possuiRastreador: false,
      imgUrl: null,
      isPF: true,
      cep: null,
      styleFoto: 'display:block',
      exibe: false,
      iconeCamera: 'camera',
      textoFoto: 'Tire uma Foto como o Exemplo abaixo',
      btnDisabled: false,
      carregando: false,
      placeHolderCpf: 'Cpf',
      maskCpf: '###.###.###-##',
      contratoVistoria: '',
      contratoAdesao: '',
      contratoRastreador: '',
      habilitarFotoGaleria: null,
      passo: 0,
      nomeArquivo: false,
      dadosProposta: {},
      tipoCambioOpcoes: ['Automático', 'Manual'],
      tipoDeCarteira: ['A', 'B', 'C', 'D', 'E', 'AB', 'AC', 'AD', 'AE'],
      estadoPneus: ['Ruim', 'Regular', 'Bom', 'Novo'],
      tiposCombustivel: [
        { text: 'Selecione', value: null },
        'Gasolina',
        'Álcool',
        'Flex',
        'GNV (Gás Natural)',
        'Elétrico',
        'Diesel',
      ],
      listaAcessorios: [],
      listaFotos: [],
      listaCores: [],
      listaCombustiveis: [],
      listaProdutosMensais: [],
      listaProdutosAAvista: [],
      dadosFinanceiros: null,
      fotoAtual: 0,
      hasImage: false,
      files: new FormData(),
      image: null,
      necessitaUpload: true,
      hasCartaoCredito: false,
      isCartaoAvista: null,
      isCartaoMensal: null,
      modalCreditCard: false,
      listCartoes: [],
      financeiroBlock: false,
      form: {
        idProspect: null,
        idCotacao: null,
        idEmpresa: null,
        assinatura: null,
        ipAceite: null,
        placaSubstituicao: null,
        detalhesAcessorios: null,
        vistoriaFeitaPeloConsultor: null,
        cordenadas: null,
        observacaoVistoria: null,
        dadosPessoais: {
          tipoPessoa: null,
          cpfcnpj: null,
          dataNascimento: null,
          rgIe: null,
          cnh: null,
          catCnh: null,
          dataVencimentoCnh: null,
          representanteLegal: null,
          cpfRepresentante: null,
          unificaAssociado: false,
          cpfDuplicado: false,
        },
        endereco: {
          cep: null,
          endereco: null,
          bairro: null,
          numero: null,
          complemento: null,
        },
        dadosVeiculo: {
          qtdPortas: null,
          cor: null,
          renavam: null,
          km: null,
          chassi: null,
          estadoPneu: null,
          anoExercicioDut: null,
          combustivel: null,
          quantidadePassageiro: null,
          numeroMotor: null,
          tipoCambio: null,
        },
        fotos: [],
        acessorios: [],
        termos: [{ termo: null }, { termo: null }, { termo: null }],
        dadosFinanceiros: {
          mensais: null,
          avista: null,
        },
        diaVencimentoMensalidade: null,
        dataVencimentoAvista: null,
        idStatusPosVistoria: null,
        qtdRecusa: null,
      },
      show: true,
      diasVencimento: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      disabledDates: {
        customPredictor: function (date) {
          // disables the date if it is a multiple of 5
          if (date < new Date()) {
            return true;
          }
        },
      },
      dataMinimaPagamento: null,
      dataMaximaPagamento: null,
      dataPadraoPagamento: null,
      dataMinimaPagamentoMensalidade: null,
      dataMaximaPagamentoMensalidade: null,
      qtdDiasVencimentoAvistaEmpresa: null,
      isBoletoAVista: null,
      cobrancaValoresAntesDaVistoria: null,
      grupos: [],
      modalPlacaSubstituicao: false,
      dicionarioChassisNacionais: [],
      cpf: null,
      formVerificaCpf: {},
      collapseId: null,
      formUnificacao: {},
    };
  },
  methods: {
    atualizarComplemento(item, index) {
      this.complementoParaEditar = { ...item };
      this.indexComplementoParaEditar = index;
      this.complementoParaEditar.update = true;
      this.modalComplemento = true;
      this.contModal = this.contModal + 1;
    },
    atualizaImplementoArray(dado) {
      this.dadosProposta.implementos[this.implementoToEditIndex] = dado;
      this.implementoToEditIndex = null;
      this.modalImplemento = false;
    },

    atualizaImplemento(item, index) {
      this.contModal = this.contModal + 1;
      this.implementoToEdit = { ...item };
      this.implementoToEditIndex = index;
      this.implementoToEdit.update = true;
      this.modalImplemento = true;
    },
    atualizarComplementoArray(dado) {
      const tipo = `${dado.tipo}s`;
      this.dadosProposta[tipo][this.indexComplementoParaEditar] = dado;
      this.indexComplementoParaEditar = null;
      this.modalComplemento = false;
    },
    validaDadoCompleto(item, tipo) {
      var retorno = true;
      if (tipo == 'Agregado' && item.placa) {
        retorno = false;
      }

      if (tipo == 'Implemento' && item.marca && item.modelo) {
        retorno = false;
      }

      return retorno;
    },
    isIOSDevice() {
      return (
        !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)
      );
    },
    verificaExtensaoImagem() {
      if (this.imgUrl) {
        if (this.imgUrl.split('/')[0] == 'data:image') return true;
        var ext = this.imgUrl.split('.').pop();
        this.tipoFoto = ext;
        if (ext == 'png' || ext == 'jpeg' || ext == 'jpg' || ext == 'gif')
          return true;
        else return false;
      } else return false;
    },
    verificaExtensaoVideo() {
      if (this.imgUrl) {
        if (this.imgUrl.split('/')[0] == 'data:video') return true;
        var ext = this.imgUrl.split('.').pop();
        this.tipoFoto = ext;
        if (ext == 'gif' || ext == 'mp4') return true;
        else return false;
      } else return false;
    },
    formataUrl(url) {
      return url.split('/').pop();
    },
    chooseFiles() {
      document.getElementById('fileUpload').click();
      //this.$refs.fileUploadBtn.click();
    },
    selecionaArquivoVideo(event) {
      this.necessitaUpload = false;
      this.hasImage = true;
      this.imgUrl = null;
      this.nomeArquivo = event.name;
      this.image = { info: {} };
      this.image.info.name = this.nomeArquivo;
      this.image.blob = event.blob;
      this.btnDisabled = false;
      if (event.base.indexOf('data:video') >= 0) {
        this.imgUrl = event.base;
        this.nomeArquivo = null;

        //
      }
    },
    selecionaArquivoGaleria(event) {
      this.necessitaUpload = true;
      this.hasImage = true;
      this.imgUrl = null;
      this.nomeArquivo = event.name;
      this.image = { info: {} };
      this.image.info.name = this.nomeArquivo;
      this.image.blob = event.blob;
      this.styleFoto = 'display:none';
      if (event.base.indexOf('data:image') >= 0) {
        this.imgUrl = event.base;
        this.nomeArquivo = null;
      }
      this.btnDisabled = false;
      /*var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.files.set("file", event.target.files[0], event.target.files[0].name);
      this.nomeArquivo = event.target.files[0].name;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {

        this.necessitaUpload = true;
        this.hasImage = true;
        var blob = canvasToBlob(reader.result);
        this.image = { info: {} };
        this.image.info.name = this.nomeArquivo;
        this.image.blob = blob;
        this.styleFoto = "display:none";
        if (event.target.files[0].type.indexOf("image") >= 0) {
          this.imgUrl = reader.result;
          //this.image.info.name = { ...this.nomeArquivo };
          this.image.info.name = event.target.files[0].name;
          this.nomeArquivo = null;
        }
      };
      reader.onerror = function (error) {

      };*/
    },
    trocaTipoPessoa() {
      if (this.form.dadosPessoais.tipoPessoa == 'Pessoa Física') {
        this.placeHolderCpf = 'Cpf';
        this.maskCpf = '###.###.###-##';
      } else {
        this.placeHolderCpf = 'Cnpj';
        this.maskCpf = '##.###.###/####-##';
      }
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName('topoForm')[0];

      if (el) {
        el.scrollIntoView();
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    selecionaCartao(item) {
      this.listCartoes.forEach((element) => {
        element.selecionado = false;
      });
      item.selecionado = true;

      service
        .post('Associado', 'AtualizarCartaoAssociadoPrincipal', {
          idProspect: this.form.idProspect,
          idAssociadoCartao: item.id_associado_cartao,
        })
        .then((res) => {})
        .catch((e) => {});
    },
    filtraCartoesSelecionados() {
      return this.listCartoes.filter((x) => x.selecionado == true);
    },
    verificaTipoPagamento() {
      this.hasCartaoCredito = false;
      this.isBoletoAVista = false;
      this.btnDisabled = false;

      this.listaProdutosMensais.forEach((element) => {
        if (element.valor) {
          if (element.valor.tipoPagamento == 'Cartão de Crédito') {
            this.hasCartaoCredito = true;
            this.isCartaoMensal = true;
            this.btnDisabled = true;
            if (this.filtraCartoesSelecionados().length > 0)
              this.btnDisabled = false;
          }
        }
      });
      this.listaProdutosAAvista.forEach((element) => {
        if (element.valor) {
          if (element.valor.tipoPagamento == 'Cartão de Crédito') {
            this.hasCartaoCredito = true;
            this.isCartaoAvista = true;
            this.btnDisabled = true;
            if (this.filtraCartoesSelecionados().length > 0)
              this.btnDisabled = false;
          }
          if (element.valor.tipoPagamento == 'Boleto') {
            this.isBoletoAVista = true;
            this.restringeDatas();
          }
        }
      });
    },
    buscarCep() {
      if (this.cep.length == 10) {
        this.form.endereco.cep = this.cep;
        this.carregando = true;
        var cepbusca = this.form.endereco.cep.replace('.', '').replace('-', '');
        service
          .post('Cep', 'BuscaCEP', {
            cep: cepbusca,
          })
          .then((res) => {
            this.encontrouCep = true;
            this.exibeCidade = true;

            const parsed = JSON.stringify(res.data);
            this.form.endereco.endereco = res.data.street;
            this.form.endereco.bairro = res.data.neighborhood;
            this.form.endereco.cidade = res.data.city;
            this.form.endereco.estado = res.data.state;
            this.form.endereco.pais = 'BR';
            this.carregando = false;
          })
          .catch((e) => {
            this.encontrouCep = false;
            this.exibeCidade = false;
            this.carregando = false;
            this.form.endereco.endereco = null;
            this.form.endereco.bairro = null;
            this.form.endereco.cidade = null;
            this.form.endereco.estado = null;
            this.form.endereco.pais = null;
            this.$bvToast.toast('Não foi possível buscar o CEP', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      }
    },
    formataStringData(data) {
      var dia = data.split('/')[0];
      var mes = data.split('/')[1];
      var ano = data.split('/')[2];

      //return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
      return [ano, mes, dia];
      // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
    },
    adicionarCartaoLista(value) {
      this.listCartoes.forEach((element) => {
        element.selecionado = false;
      });

      this.modalCreditCard = false;
      this.listCartoes.push(value);

      this.btnDisabled = false;
    },
    verificaTipoCotacao(passo) {
      //if(dadosProposta)
      if (this.dadosProposta.tipoProposta) {
        //se estiver em acessorios
        if (
          this.passo == 3 &&
          this.dadosProposta.tipoProposta == 'Reativação'
        ) {
          this.disparaRoute(5);
        }
        //Reativação nao tem termo de filiação e nem acessorio
        if (
          this.passo == 6 &&
          this.dadosProposta.tipoProposta == 'Reativação'
        ) {
          this.disparaRoute(7);
        }
      }
    },
    verificaPasso() {
      this.passo = this.$route.params.passo ? this.$route.params.passo : 0;
      this.fotoAtual = this.$route.params.fotoAtual
        ? this.$route.params.fotoAtual
        : 0;
      let arrPassos = [
        'Dados Pessoais',
        'Endereço',
        'Dados do Veículo',
        'Acessórios',
        'Financeiro',
        'Termo de Vistoria',
        'Termo de Adesão',
        'Termo de Rastreador',
        'Fotos',
        'Assinatura',
        'Resultado',
      ];
      return arrPassos[this.passo];
    },
    voltar() {
      if (this.passo == 4 && this.permitirAcessorios == false) {
        this.passo--;
      }

      if (this.passo == 8) {
        if (this.fotoAtual > 0) {
          this.fotoAtual--;
          this.atualizaFotoAtual();
        } else this.passo--;
      } else this.passo = this.passo - 1;
      if (this.passo == 0) {
        this.$router.push(`/AceiteCotacao/${this.form.chave}`);
      } else this.disparaRoute(this.passo, this.fotoAtual);
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    limparAssinatura() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.clearSignature();
      } else {
        this.assinaturaParcial = null;
        this.exibeCanvaAssinatura = false;
      }
      /*if (this.screenPortrait) {
        this.assinaturaParcial = null;
        this.exibeCanvaAssinatura = true;
      }*/
    },
    verificaAssinatura() {
      var retorno = false;
      if (this.$refs.signaturePad) {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        retorno = !isEmpty;
        if (!isEmpty) this.btnDisabled = false;
      }

      return retorno;
    },
    onSubmit(evt) {
      this.$validator.validateAll().then(async (result) => {
        if (!result && !this.form.dadosPessoais.unificaAssociado) {
          this.popToast();
          return;
        }

        if (this.passo == 0) {
          if (
            cpfCnpj.valida_cpf_cnpj(this.form.dadosPessoais.cpfcnpj) == false
          ) {
            this.$bvToast.toast('Este CPF/CNPJ não é válido', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 3500,
            });

            return;
          }

          //validformataStringData
          var dataVencimentoFormatada = moment(
            this.formataStringData(this.form.dadosPessoais.dataVencimentoCnh),
          );

          var dataAtual = moment();
          if (dataAtual.diff(dataVencimentoFormatada, 'days') > 30) {
            this.$bvToast.toast('CNH vencida há mais de 30 dias', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 3500,
            });
            return;
          }
        }

        if (this.passo == 2) {
          //validaImplemento
          if (this.dadosProposta.implementos) {
            var valida = true;
            this.dadosProposta.implementos.forEach((element) => {
              if (!this.validaDadoCompleto(element, 'Implemento') == false) {
                valida = false;
              }
            });
            if (valida == false) {
              this.$bvToast.toast(`Possui Implemento com dados Incompletos `, {
                title: 'Atenção!',
                solid: true,
                variant: 'danger',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 3500,
              });
              return;
            }
          }

          //validaAgregado
          if (this.dadosProposta.agregado) {
            var valida = true;
            this.dadosProposta.agregados.forEach((element) => {
              if (!this.validaDadoCompleto(element, 'Agregado') == false) {
                valida = false;
              }
            });
            if (valida == false) {
              this.$bvToast.toast(`Possui Agregado com dados Incompletos `, {
                title: 'Atenção!',
                solid: true,
                variant: 'danger',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 3500,
              });
              return;
            }
          }

          if (this.dadosProposta.tipoProposta == 'Substituição') {
            const _placaSubstituicao =
              this.form.placaSubstituicao.toUpperCase();
            const _dadosVeiculoplaca =
              this.dadosProposta.dadosVeiculo.placa.toUpperCase();

            const placaSaoIguais = _placaSubstituicao == _dadosVeiculoplaca;

            if (placaSaoIguais) {
              this.modalPlacaSubstituicao = true;
              return;
            }
          }

          const anoDutValido =
            moment().year() - this.form.dadosVeiculo.anoExercicioDut >= 4;

          if (anoDutValido && !this.isSomenteAgregado()) {
            this.$bvToast.toast(
              `O Ano exercicio deve ser maior ou igual à ${
                moment().year() - 3
              }`,
              {
                title: 'Atenção!',
                solid: true,
                variant: 'danger',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 3500,
              },
            );
            return;
          }

          if (
            (!this.form.dadosVeiculo.km ||
              this.form.dadosVeiculo.km == 0 ||
              this.form.dadosVeiculo.km == null) &&
            !this.isSomenteAgregado()
          ) {
            this.carregando = false;
            this.$bvToast.toast(
              'A quilometragem não pode ser zero, foi alterado para 1 ',
              {
                title: 'Atenção!',
                solid: true,
                variant: 'warning',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 3500,
              },
            );

            this.form.dadosVeiculo.km = 1;
            return;
          }
        }

        if (this.passo == 4) {
          //valida se selecionou as formas de pagamento
          var msg = [];
          this.listaProdutosMensais.forEach((element) => {
            if (element.valor == null) {
              msg.push(
                'Selecione a Forma de Pagamento do item ' + element.nomeProduto,
              );
            }
          });
          this.listaProdutosAAvista.forEach((element) => {
            if (element.valor == null) {
              msg.push(
                'Selecione a Forma de Pagamento do item ' + element.nomeProduto,
              );
            }
          });
          if (msg.length > 0) {
            const h = this.$createElement;
            var arrAux = [];
            msg.forEach((element) => {
              arrAux.push(h('p', {}, element));
            });
            // Create the message
            const vNodesMsg = h(
              'p',
              { class: ['text-center', 'mb-0'] },
              arrAux,
            );

            // Pass the VNodes as an array for message and title
            this.$bvToast.toast([vNodesMsg], {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 3500,
            });
            return;
          }

          if (typeof this.form.diaVencimentoMensalidade == 'string') {
            this.form.diaVencimentoMensalidade = Number(
              this.form.diaVencimentoMensalidade.split('-')[2],
            );
          }
          this.form.dadosFinanceiros.mensais = this.listaProdutosMensais;
          this.form.dadosFinanceiros.avista = this.listaProdutosAAvista;
        }

        if (this.passo == 9) {
          if (!this.form.assinatura) {
            if (this.$refs.signaturePad) {
              const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
              if (isEmpty == true) {
                this.$bvToast.toast('É necessário fazer sua assinatura', {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 3500,
                });
                return;
              } else {
                const blob = this.screenPortrait
                  ? canvasToBlob(this.assinaturaParcial)
                  : canvasToBlob(data);
                await this.uploadSignature(blob);
              }
            } else {
              if (!this.assinaturaParcial) {
                this.$bvToast.toast('É necessário fazer sua assinatura', {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 3500,
                });
                return;
              } else {
                const blob = canvasToBlob(this.assinaturaParcial);
                await this.uploadSignature(blob);
              }
            }
          }
        }

        if (this.passo == 10) {
          if (
            this.dadosProposta.nomeStatus == 'Pendente' ||
            this.dadosProposta.nomeStatus == 'Em Processo de Vistoria'
          ) {
            service
              .post('Cotacao', 'AtualizaVistoriaPendente', this.form)
              .then((res) => {})
              .catch((e) => {});
          }
          this.$router.push('/dashboard');
          return;
        }

        this.btnDisabled = true;

        if (this.passo == 8) {
          //chama funcao de upload da fotoatual
          await this.uploadFoto()
            .then(async (res) => {
              await service.post('Cotacao', 'addFotoVistoria', {
                form: this.form,
                foto: this.listaFotos[this.fotoAtual],
                upload: res,
              });
              if (this.fotoAtual < this.listaFotos.length - 1) {
                this.fotoAtual++;
                this.atualizaFotoAtual();
                this.disparaRoute(this.passo);
                return;
              } else {
                if (this.dadosProposta.nomeStatus == 'Pendente') {
                  service
                    .post('Cotacao', 'AtualizaVistoriaPendente', this.form)
                    .then((res) => {})
                    .catch((e) => {});
                  if (this.dadosProposta.assinatura == null) {
                    this.passo = 9;
                  } else {
                    this.passo = 10;
                  }
                  this.disparaRoute(this.passo);
                } else {
                  if (this.dadosProposta.assinatura == null) {
                    this.passo = 9;
                  } else {
                    this.passo = 10;
                  }
                  this.disparaRoute(this.passo);
                }
              }
            })
            .catch((e) => {
              return;
            });
        } else {
          this.form.passo = this.passo;
          this.form.fotoAtual = this.fotoAtual;
          this.carregando = true;
          service
            .post('Cotacao', 'AtualizaVistoria', this.form)
            .then((res) => {
              if (this.form.dadosPessoais.unificaAssociado) {
                this.unificarAssociados();
                this.carregando = false;
                window.location.reload();
                return;
              }
              this.carregando = false;
              this.$forceUpdate();
              if (this.passo == 2 && this.permitirAcessorios == false) {
                this.disparaRoute(4);
              } else {
                this.disparaRoute();
              }
            })
            .catch((e) => {});
        }
      });
    },
    disparaRoute(passo, foto) {
      if (passo || +passo === 0) {
        if (foto) {
          this.$router.push(
            `/AceiteCotacao/${this.form.chave}/${passo}/${foto}`,
          );
        } else {
          this.$router.push(
            `/AceiteCotacao/${this.form.chave}/${passo}/${this.fotoAtual}`,
          );
        }
      } else {
        this.$router.push(
          `/AceiteCotacao/${this.form.chave}/${parseInt(this.passo) + 1}/${
            this.fotoAtual
          }`,
        );
      }
    },
    uploadSignature(blob) {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        this.files.set('file', blob, 'assinatura.png');
        service
          .upload('Upload', 'Assinatura/' + this.form.idCotacao, this.files)
          .then((response) => {
            this.carregando = false;
            this.form.assinatura = response;
            resolve(response);
          })
          .catch(function (error) {
            this.carregando = false;
            reject();
          });
      });
    },
    uploadFoto() {
      return new Promise((resolve, reject) => {
        if (this.necessitaUpload == false && this.hasImage == false) {
          resolve(this.imgUrl);
          return;
        }

        if (this.hasImage == true) {
          this.carregando = true;
          this.files.set('file', this.image.blob, this.image.info.name);

          service
            .upload('Upload', 'Vistoria/' + this.form.idCotacao, this.files)
            .then((response) => {
              this.carregando = false;
              this.hasImage = false;
              this.form.fotos.push({
                imagem: this.image.info.name,
                foto: this.listaFotos[this.fotoAtual],
                url: response,
              });
              resolve(response);
            })
            .catch((error) => {
              this.carregando = false;
              this.$bvToast.toast(
                'Não foi possível fazer o upload dessa foto, verifique sua conexão e tente novamente.',
                {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 5500,
                },
              );
              reject();
            });
        } else {
          if (this.listaFotos[this.fotoAtual].obrigatorio) {
            this.$bvToast.toast('É necessário adicionar uma foto', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          } else {
            this.preencheForm();
            resolve('');
            return;
          }
        }
      });
    },
    async buscaDadosCotacao(buscarFoto = false) {
      if (this.$route.params.chave) {
        this.carregando = true;
        const res = await service.post('Cotacao', 'listarCotacoes', {
          chave: this.$route.params.chave,
        });

        this.dadosProposta = res.data[0];

        const statusProposta = this.dadosProposta.idStatus;
        if (statusProposta == 2 || statusProposta == 4) {
          this.$router.push(`/Dashboard`);

          return;
        }

        localStorage.setItem('idGrupo', this.dadosProposta.idGrupo);
        localStorage.setItem('idProposta', this.dadosProposta.idProposta);
        localStorage.setItem('isZero', this.dadosProposta.isZero);

        this.exibe = true;
        if (this.dadosProposta.dadosCliente.tipo != 'Pessoa Física') {
          this.placeHolderCpf = 'Cnpj';
          this.maskCpf = '##.###.###/####-##';
        }
        if (this.dadosProposta.nomeStatus == 'Reprovada') {
          this.passo = 5;
        }
        this.preencheForm();

        this.carregando = false;
        this.inicializaAssinatura();

        this.habilitarFotoGaleria = this.dadosProposta.habilitarFotoGaleria;

        localStorage.setItem(
          'habilitarFotoGaleria',
          this.dadosProposta.habilitarFotoGaleria,
        );

        if (this.passo == 2) this.buscaCoresECombustivel();
        if (this.passo == 3) this.buscaAcessorios();
        if (this.passo == 4) this.buscaFormasPagamento();
        if (this.passo == 7) this.validaProdutosRastreador();

        if (this.passo == 5 || this.passo == 6 || this.passo == 7) {
          this.buscaDadosContrato();
        }

        if (buscarFoto == true) {
          if (this.passo == 8) {
            this.buscaFotos();
          }
        }

        this.buscaConfigEmpresa();
        this.verificaTipoCotacao(this.passo);
        //});
      }
    },
    preencheForm() {
      this.form.idCotacao = this.dadosProposta.idProposta;
      this.form.idProspect = this.dadosProposta.dadosCliente.idProspect;
      this.form.idEmpresa = this.dadosProposta.idEmpresa;
      this.form.assinatura = this.dadosProposta.assinatura;
      this.cpf = this.dadosProposta.dadosCliente.cpf;
      this.form.dadosPessoais.dataNascimento =
        this.dadosProposta.dadosCliente.dataNascimento;
      this.form.dadosPessoais.rgIe = this.dadosProposta.dadosCliente.rg_ie;
      this.form.dadosPessoais.cnh = this.dadosProposta.dadosCliente.cnh;
      this.form.dadosPessoais.catCnh = this.dadosProposta.dadosCliente.catCnh;
      this.form.dadosPessoais.dataVencimentoCnh =
        this.dadosProposta.dadosCliente.dataVencimentoCnh;
      this.form.dadosPessoais.representanteLegal =
        this.dadosProposta.dadosCliente.representanteLegal;
      this.form.dadosPessoais.cpfRepresentante =
        this.dadosProposta.dadosCliente.cpfRepresentante;
      this.form.endereco.numero = this.dadosProposta.dadosCliente.numero;
      this.form.endereco.complemento =
        this.dadosProposta.dadosCliente.complemento;
      this.form.dadosVeiculo.qtdPortas =
        this.dadosProposta.dadosVeiculo.qtdPortas;
      this.form.dadosVeiculo.cor = this.dadosProposta.dadosVeiculo.cor;
      this.form.dadosVeiculo.renavam = this.dadosProposta.dadosVeiculo.renavam;
      this.form.dadosVeiculo.km = this.dadosProposta.dadosVeiculo.km;
      this.form.dadosVeiculo.chassi = this.dadosProposta.dadosVeiculo.chassi;
      this.form.dadosVeiculo.estadoPneu =
        this.dadosProposta.dadosVeiculo.estadoPneu;
      this.form.dadosVeiculo.quantidadePassageiro =
        this.dadosProposta.dadosVeiculo.quantidadePassageiro;
      this.form.dadosVeiculo.anoExercicioDut =
        this.dadosProposta.dadosVeiculo.anoExercicioDut;
      this.form.dadosVeiculo.numeroMotor =
        this.dadosProposta.dadosVeiculo.numeroMotor;
      this.form.dadosVeiculo.tipoCambio =
        this.dadosProposta.dadosVeiculo.tipoCambio;

      var jsonFacilitador = this.dadosProposta.jsonFacilitador
        ? JSON.parse(this.dadosProposta.jsonFacilitador)
        : {};
      this.dadosFinanceiros = jsonFacilitador.dadosFinanceiros;

      if (this.cobrancaValoresAntesDaVistoria) {
        this.financeiroBlock = true;
      }

      this.form.dadosVeiculo.combustivel =
        this.dadosProposta.dadosVeiculo.combustivel;
      this.form.detalhesAcessorios = this.dadosProposta.detalhesAcessorios;
      this.form.placaSubstituicao = this.dadosProposta.placaSubstituicao;
      this.form.isZero = this.dadosProposta.isZero;
      this.form.habilitarFotoGaleria = this.dadosProposta.habilitarFotoGaleria;
      this.form.dadosPessoais.tipoPessoa = this.dadosProposta.dadosCliente.tipo;
      if (this.$route.query.isConsultor)
        this.form.vistoriaFeitaPeloConsultor = this.$route.query.isConsultor;
      else
        this.form.vistoriaFeitaPeloConsultor =
          this.dadosProposta.vistoriaFeitaPeloConsultor;
      this.form.diaVencimentoMensalidade =
        this.dadosProposta.diaVencimentoMensalidade;
      this.form.dataVencimentoAvista = this.dadosProposta.dataVencimentoAvista;
      this.form.idStatusPosVistoria =
        this.dadosProposta.dadosVendedor.idStatusPosVistoria;
      this.form.qtdRecusa = this.dadosProposta.qtdRecusa;
    },
    buscaDadosContrato() {
      this.carregando = true;
      service
        .post('ModelosContratos', 'BuscaContrato', {
          idEmpresa: this.form.idEmpresa,
        })
        .then((res) => {
          this.contratoVistoria = res.data.find(
            (x) => x.tipoContrato == 'Vistoria',
          );

          this.contratoAdesao = res.data.find(
            (x) => x.tipoContrato == 'Proposta de Filiação',
          );

          this.contratoRastreador = res.data.find(
            (x) => x.tipoContrato == 'Devedor Solidário',
          );

          this.carregando = false;
        });
    },
    atualizaFotoAtual() {
      this.styleFoto = 'display:block';

      if (
        this.listaFotos[this.fotoAtual].nome != null &&
        this.listaFotos[this.fotoAtual].aprovada == null
      ) {
        this.imgUrl = this.listaFotos[this.fotoAtual].nome;
        this.necessitaUpload = false;
      } else {
        this.necessitaUpload = true;
        this.imgUrl = `${this.enderecoImgPadrao}${
          this.listaFotos[this.fotoAtual].fotoPadrao
        }`;
      }

      if (this.listaFotos[this.fotoAtual].nomePosicao.indexOf('Video') >= 0) {
        this.iconeCamera = 'video';
        this.textoFoto = 'Faça um vídeo como o Exemplo abaixo';
      }
    },
    buscaAcessorios() {
      this.carregando = true;
      service
        .post('Cotacao', 'listarAcessorios', {
          idEmpresa: this.form.idEmpresa,
          idGrupo: this.dadosProposta.idGrupo,
          idProposta: this.form.idCotacao,
        })
        .then((res) => {
          this.listaAcessorios = res.data;
          this.listaAcessorios.forEach((element) => {
            if (element.adicionado == 1)
              this.form.acessorios.push(element.id_acessorio);
          });
          this.carregando = false;
        });
    },
    buscaCoresECombustivel() {
      this.carregando = true;
      service
        .post('Cotacao', 'listarCoresECombustivel', {
          idRegional: this.dadosProposta.idRegional,
          idProposta: this.form.idCotacao,
        })
        .then((res) => {
          this.listaCores.push({ label: 'Selecione', id: null });
          this.listaCombustiveis.push({ label: 'Selecione', id: null });

          res.data.cores.forEach((element) => {
            this.listaCores.push({
              label: element.descricao,
              id: element.descricao,
            });
          });

          res.data.combustiveis.forEach((element) => {
            this.listaCombustiveis.push({
              label: element.descricao,
              id: element.descricao,
            });
          });

          this.carregando = false;
        });
    },
    validaProdutosRastreador() {
      this.carregando = true;
      service
        .post('Cotacao', 'validaProdutosRastreador', {
          idEmpresa: this.form.idEmpresa,
          produtos: this.dadosProposta.produtos,
          idProposta: this.form.idCotacao,
        })
        .then((res) => {
          this.possuiRastreador = res.data.possuiRastreador;
          this.carregando = false;
          if (this.possuiRastreador == false) this.disparaRoute(8);
        });
    },

    buscaFormasPagamento() {
      if (this.passo == 4) {
        this.carregando = true;
        service
          .post('Cotacao', 'buscaFormasPagamento', {
            idEmpresa: this.form.idEmpresa,
            idProposta: this.form.idCotacao,
          })
          .then((res) => {
            this.cobrancaValoresAntesDaVistoria =
              res.data.cobrancaValoresAntesDaVistoria;
            this.listCartoes = res.data.cartoes;
            var formas = res.data.formasPagamento;
            var aux = [];

            if (this.cobrancaValoresAntesDaVistoria == false)
              for (let index = 0; index < formas.length; index++) {
                const element = formas[index];
                var valor = null;

                if (this.dadosFinanceiros)
                  if (element.isMensal) {
                    var findValor = this.dadosFinanceiros.mensais.find(
                      (x) => x.nomeProduto == element.nomeProduto,
                    );
                    if (findValor) valor = findValor.valor;
                  } else {
                    var findValor = this.dadosFinanceiros.avista.find(
                      (x) => x.nomeProduto == element.nomeProduto,
                    );
                    if (findValor) valor = findValor.valor;
                  }

                if (index == 0) {
                  aux.push({
                    idProduto: element.idProduto,
                    nomeProduto: element.nomeProduto,
                    isMensal: element.isMensal,
                    valor: valor,
                    formas: [
                      {
                        value: {
                          idTipoPagamentoEmpresa:
                            element.id_tipoPagamentoEmpresa,
                          tipoPagamento: element.nomePagamento,
                        },
                        text: element.nomePagamento,
                      },
                    ],
                  });
                } else {
                  if (element.nomeProduto == formas[index - 1].nomeProduto) {
                    var aux2 = aux.find(
                      (x) => x.nomeProduto == element.nomeProduto,
                    );
                    aux2.formas.push({
                      value: {
                        idTipoPagamentoEmpresa: element.id_tipoPagamentoEmpresa,
                        tipoPagamento: element.nomePagamento,
                      },
                      text: element.nomePagamento,
                    });
                  } else {
                    aux.push({
                      idProduto: element.idProduto,
                      nomeProduto: element.nomeProduto,
                      isMensal: element.isMensal,
                      valor: valor,
                      formas: [
                        {
                          value: {
                            idTipoPagamentoEmpresa:
                              element.id_tipoPagamentoEmpresa,
                            tipoPagamento: element.nomePagamento,
                          },
                          text: element.nomePagamento,
                        },
                      ],
                    });
                  }
                }
              }
            this.listaProdutosMensais = [];
            this.listaProdutosAAvista = [];
            aux.forEach((element) => {
              if (element.isMensal) this.listaProdutosMensais.push(element);
              else this.listaProdutosAAvista.push(element);
            });
            if (this.listaProdutosMensais.length > 0)
              this.listaProdutosMensais.forEach((element) => {
                element.formas.unshift({ value: null, text: 'Selecione' });
              });

            if (this.listaProdutosAAvista.length > 0)
              this.listaProdutosAAvista.forEach((element) => {
                element.formas.unshift({ value: null, text: 'Selecione' });
              });

            this.verificaTipoPagamento();
            this.carregando = false;
          });
      }
    },
    buscaFotos() {
      if (this.passo == 8) {
        var idEmpresa = localStorage.getItem('idEmpresa');
        var idGrupo = localStorage.getItem('idGrupo');
        var idProposta = localStorage.getItem('idProposta');
        var isZero = localStorage.getItem('isZero');
        var habilitarFotoGaleria = localStorage.getItem('habilitarFotoGaleria');
        if (habilitarFotoGaleria)
          this.habilitarFotoGaleria = JSON.parse(habilitarFotoGaleria);
        else {
          this.buscaDadosCotacao(true);
          return;
        }
        if (!idProposta || !idGrupo) {
          this.buscaDadosCotacao(true);
          return;
        }
        this.form.idCotacao = idProposta;
        this.carregando = true;
        service
          .post('Cotacao', 'listarTiposFotos', {
            idEmpresa: idEmpresa,
            idGrupo: idGrupo,
            idProposta: idProposta,
            isZero: isZero,
          })
          .then((res) => {
            this.exibe = true;
            this.listaFotos = res.data;
            if (this.listaFotos[this.fotoAtual]) {
              const temObservacao =
                this.listaFotos[this.fotoAtual].observacaoVistoria;
              if (temObservacao)
                this.form.observacaoVistoria =
                  this.listaFotos[this.fotoAtual].observacaoVistoria;
            }
            if (this.listaFotos.length == 0) {
              const inicio = this.$route.query.inicio;
              if (inicio && this.dadosProposta.assinatura) {
                this.redirecionaParaInicio();
              } else if (this.dadosProposta.assinatura == null) {
                this.disparaRoute(9);
              } else {
                this.disparaRoute(10);
              }
            } else {
              this.atualizaFotoAtual();
            }
            this.carregando = false;
          });
      }
    },
    redirecionaParaInicio() {
      this.$router.push(
        `/AceiteCotacao/${this.form.chave}/${0}/${this.fotoAtual}`,
      );
    },
    startImageResize() {
      //this.carregando = true;
    },
    endImageResize() {
      this.carregando = false;
    },
    setImageNew(newImage) {
      this.necessitaUpload = true;
      this.hasImage = true;
      this.imgUrl = newImage;
      var blob = canvasToBlob(this.imgUrl);
      this.image = { info: {} };
      this.image.info.name = this.fotoAtual + '.png';
      this.image.blob = blob;
      this.styleFoto = 'display:none';
      this.habilitaCamera = false;
      this.btnDisabled = false;
    },
    setImage: function (output) {
      this.iniciouClickUpload = false;
      this.necessitaUpload = true;
      this.hasImage = true;
      this.image = output;
      this.imgUrl = output.dataUrl;
      var blob = canvasToBlob(this.imgUrl);
      this.image.blob = blob;
      this.styleFoto = 'display:none';
      this.btnDisabled = false;
    },
    async buscaDadosToken() {
      try {
        const token = localStorage.getItem('user-token');
        if (token) {
          this.decoded = jwt.decode(token);
          this.idEmpresa = this.decoded.id_empresa;

          if (this.decoded.tipo == 'Associado') {
            this.form.vistoriaFeitaPeloConsultor = false;
          } else {
            this.form.vistoriaFeitaPeloConsultor = true;
          }
        }

        this.form.chave = this.$route.params.chave;
      } catch (error) {
        console.log(2786, error);
      }
    },
    getLocation() {
      var latitude = localStorage.getItem('latitude');
      var longitude = localStorage.getItem('longitude');
      if (!latitude || !longitude) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            this.showPosition,
            this.positionError,
          );
        }
      } else {
        this.form.cordenadas = {
          lat: latitude,
          lng: longitude,
          altitude: null,
          altitudeAccuracy: null,
          accuracy: 43,
        };
      }
    },
    showPosition(position) {
      this.mostraErroGeo = false;
      localStorage.setItem('latitude', position.coords.latitude);
      localStorage.setItem('longitude', position.coords.longitude);
      this.form.cordenadas = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        altitude: null,
        altitudeAccuracy: null,
        accuracy: 43,
      };
    },
    positionError() {
      this.mostraErroGeo = true;
    },

    getIP() {
      var ip = localStorage.getItem('ip');
      if (!ip) {
        fetch('https://api.ipify.org?format=json')
          .then((response) => response.json())
          .then((response) => {
            this.form.ipAceite = response.ip;
            localStorage.setItem('ip', response.ip);
          });
      } else {
        this.form.ipAceite = ip;
      }
    },
    getSize() {
      this.temaCor = colors.getColorsTema();
      this.screenPortrait = window.matchMedia(
        '(orientation: portrait)',
      ).matches;
      // if (screen.height < 650)
      //  this.heightAssinatura.height = `${screen.height - 400}px`;
      // else this.heightAssinatura.height = `${screen.height - 350}px`;
    },
    buscaConfigEmpresa() {
      try {
        this.carregando = true;
        service
          .getAll('g', {}, 'empresas/list/idEmpresa/' + this.form.idEmpresa)
          .then((res) => {
            this.permitirAcessorios = res.data[0].permitirAcessorios;

            if (res.data[0].diasVencimento) {
              // somente para empresa teste e bp
              if (utils.verificaEmpresaBemProtege(this.form.idEmpresa)) {
                this.diasVencimento = this.optionsDiasVencimento(
                  res.data[0].diasVencimento,
                );
              } else {
                this.diasVencimento = res.data[0].diasVencimento.split(',');
              }
            }
            if (res.data[0].qtdDiasVencimentoAvista) {
              this.qtdDiasVencimentoAvistaEmpresa =
                res.data[0].qtdDiasVencimentoAvista;
            }
            this.carregando = false;
            this.show = true;
          })
          .catch(() => {
            this.carregando = false;
          });
      } catch (error) {
        this.$bvToast.toast(
          'Não foi possível buscar as configurasções da empresa, tente novamente.',
          {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 3500,
          },
        );
      }
    },
    optionsDiasVencimento(arrayDiasVencimento) {
      const dataVistoria = new Date();
      const diaDoMesVistoria = dataVistoria.getDate();
      const arrStr = arrayDiasVencimento
        .split(',')
        .map(Number) // aqui converto o array de strings em numbers
        .sort((a, b) => a - b); // ordeno do menor para o maior
      if (diaDoMesVistoria >= 1 && diaDoMesVistoria <= 31) {
        const maxDay = Math.min(diaDoMesVistoria, 31); // seta o maxDay baseado no dia atual garantindo que seja menor que 31

        const novoArrayVencimento = arrStr.filter(
          (element) => element <= maxDay + 4 && maxDay - element <= 5,
        );
        if (diaDoMesVistoria >= 16) novoArrayVencimento.push(10);
        if (diaDoMesVistoria >= 21) novoArrayVencimento.push(15);
        if (diaDoMesVistoria >= 26) novoArrayVencimento.push(20);

        return novoArrayVencimento.sort((a, b) => a - b);
      }
    },
    desabilitaDatas(ymd, date) {
      // Desabilita finais de semana (Domingo = `0`, Sábado = `6`)
      const weekday = date.getDay();
      // Retorna `true` se a data deverá ser desabilitada
      return weekday === 0 || weekday === 6;
    },
    desabilitaDatasMensalidade(ymd, date) {
      const hoje = new Date().getDate();
      const mesAtual = new Date().getMonth();
      const dayCalendar = date.getDate();
      const monthCalendar = date.getMonth();

      //Regras para o calendário do mês atual
      if (mesAtual == monthCalendar) {
        if (hoje >= 1 && hoje <= 3) {
          return (
            dayCalendar !== 10 &&
            dayCalendar !== 15 &&
            dayCalendar !== 20 &&
            dayCalendar !== 25 &&
            dayCalendar !== 30
          );
        }

        if (hoje >= 4 && hoje <= 8) {
          return (
            dayCalendar !== 15 &&
            dayCalendar !== 20 &&
            dayCalendar !== 25 &&
            dayCalendar !== 30
          );
        }

        if (hoje >= 9 && hoje <= 13) {
          return dayCalendar !== 20 && dayCalendar !== 25 && dayCalendar !== 30;
        }

        if (hoje >= 14 && hoje <= 18) {
          return dayCalendar !== 25 && dayCalendar !== 30;
        }

        if (hoje >= 19 && hoje <= 23) {
          return dayCalendar !== 30;
        }

        if (hoje >= 24 && hoje <= 31) {
          return true;
        }
      } else {
        //Regras para o calendário do próximo mês
        if (hoje >= 1 && hoje <= 5) {
          return dayCalendar !== 5;
        }

        if (hoje >= 6 && hoje <= 10) {
          return dayCalendar !== 5 && dayCalendar !== 10;
        }

        if (hoje >= 11 && hoje <= 15) {
          return dayCalendar !== 10 && dayCalendar !== 15;
        }

        if (hoje >= 16 && hoje <= 20) {
          return dayCalendar !== 10 && dayCalendar !== 15 && dayCalendar !== 20;
        }

        if (hoje >= 21 && hoje <= 25) {
          return (
            dayCalendar !== 10 &&
            dayCalendar !== 15 &&
            dayCalendar !== 20 &&
            dayCalendar !== 25
          );
        }

        if (hoje >= 26 && hoje <= 31) {
          return (
            dayCalendar !== 10 &&
            dayCalendar !== 15 &&
            dayCalendar !== 20 &&
            dayCalendar !== 25 &&
            dayCalendar !== 30
          );
        }
      }
    },
    restringeDatas() {
      const now = new Date();
      const weekday = now.getDay();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      const minDay = new Date(today);
      if (weekday === 5) {
        minDay.setDate(minDay.getDate() + 3);
      } else if (weekday === 6) {
        minDay.setDate(minDay.getDate() + 2);
      } else {
        minDay.setDate(minDay.getDate() + 1);
      }

      const maxDay = new Date(today);
      if (weekday == 5 || 6 || 0) {
        maxDay.setDate(
          minDay.getDate() + this.qtdDiasVencimentoAvistaEmpresa - 1,
        );
      } else {
        maxDay.setDate(minDay.getDate() + this.qtdDiasVencimentoAvistaEmpresa);
      }

      this.form.dataVencimentoAvista = minDay.toISOString().slice(0, 10);
      this.dataMinimaPagamento = minDay.toISOString().slice(0, 10);
      this.dataMaximaPagamento = maxDay.toISOString().slice(0, 10);
    },
    restringeDatasMensalidade() {
      const month = new Date().getMonth();
      const today = new Date();
      this.dataMinimaPagamentoMensalidade = new Date(
        today.getFullYear(),
        today.getMonth(),
        1,
      );
      this.dataMaximaPagamentoMensalidade = new Date(
        today.getFullYear(),
        today.getMonth() + 2,
        0,
      );
    },
    inicializaAssinatura() {
      // console.log(
      //   'INicializa Assinatura',
      //   this.screenPortrait,
      //   this.$refs.signaturePad,
      // );
    },
    verificaEmpresa() {
      const idEmpresa = localStorage.getItem('idEmpresa');
      return this.utils.verificaEmpresaBemProtege(idEmpresa);
    },
    buscarDicionarioChassisNacionais() {
      service
        .post('dicionarios', 'buscar-dicionario-chassis-nacionais', {})
        .then((res) => {
          this.dicionarioChassisNacionais = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    verificarGrupo() {
      const importado = this.verificaVeiculoImportado();
      const grupoPropostaImportado = this.verificaGrupoPropostaImportado();
      const grupoImportadoExiste = this.separarGrupoImportado();
      grupoImportadoExiste &&
        importado &&
        !grupoPropostaImportado &&
        this.reatribuirGrupoParaImportado();
    },
    verificaVeiculoImportado() {
      if (this.form.dadosVeiculo.chassi) {
        const chassi = this.form.dadosVeiculo.chassi.slice(0, 2);
        return !this.dicionarioChassisNacionais.includes(chassi.toUpperCase());
      } else {
        return false;
      }
    },
    verificaGrupoPropostaImportado() {
      const grupoProposta = this.grupos.find(
        (grupo) => grupo.idGrupo === this.dadosProposta.idGrupo,
      );
      return grupoProposta ? grupoProposta.importado : true;
    },
    async reatribuirGrupoParaImportado() {
      const {
        idGrupo,
        idEmpresa,
        idProposta,
        observacao,
        restricoes,
        codigoMarca,
        codigoModelo,
        cilindradas,
        valorCobertura,
        estado,
        cidade,
      } = this.reuneDados();

      const novaCotaParticipacao = await this.requsicaoPost(
        {
          idEmpresa,
          idGrupo,
          idMarca: codigoMarca,
          idModelo: codigoModelo,
          estado,
          cidade,
        },
        'Cotacao',
        'buscarCotaParticipacaoAtualizada',
      );

      const novaCotaTabelaValor = await this.requsicaoPost(
        {
          idGrupo,
          valorCobertura,
          restricoes,
          cilindradas,
          estadoParaTabelaValor: estado,
          cidadeParaTabelaValor: cidade,
        },
        'Grupo',
        'busca-cota-tabela-valor',
      );

      const atualizaTabelaProposta = await this.requsicaoPost(
        {
          idProposta,
          idGrupo,
          observacao: this.adicionaPedidoRevisaoNaObservacao(observacao),
          valorCotaParticipacao: novaCotaParticipacao.valorCota,
          valorParticipacaoMinimo: novaCotaParticipacao.valorParticipacao,
          cotaTabela: String(novaCotaTabelaValor.cota),
          idTabelaValor: novaCotaTabelaValor.id_tabelaValor,
        },
        'Grupo',
        'atualiza-tabela-proposta',
      );

      const atualizaTabelaPropostaFotos = await this.requsicaoPost(
        {
          idProposta,
        },
        'Grupo',
        'atualiza-tabela-propostaFotos',
      );
    },
    reuneDados() {
      const { idGrupo } = this.separarGrupoImportado();
      const {
        idEmpresa,
        idProposta,
        restricoes,
        observacao,
        dadosVeiculo: {
          codigoMarca,
          codigoModelo,
          cilindradas,
          valorCobertura,
        },
        dadosCliente: { estado, cidade },
      } = this.dadosProposta;

      return {
        idGrupo,
        idEmpresa,
        idProposta,
        restricoes,
        observacao,
        codigoMarca,
        codigoModelo,
        cilindradas,
        valorCobertura,
        estado,
        cidade,
      };
    },
    adicionaPedidoRevisaoNaObservacao(observacao) {
      const frase =
        'O grupo do veículo foi alterado automaticamente para um grupo importado durante a vistoria.';
      const pedidoDeRevisao = 'Por favor, revise o grupo.';

      if (!observacao) {
        return frase + ' ' + pedidoDeRevisao;
      }

      return observacao.includes(frase)
        ? observacao + ' ' + pedidoDeRevisao
        : observacao + ' ' + frase + ' ' + pedidoDeRevisao;
    },
    separarGrupoImportado() {
      return this.grupos.find(
        (grupo) =>
          grupo.importado &&
          this.dadosProposta.dadosVeiculo.codTipoVeiculo === grupo.idTipoFipe,
      );
    },
    requsicaoPost(body, rota, subRota) {
      return service
        .post(rota, subRota, body)
        .then((res) => res.data)
        .catch((e) => console.log('erro', e));
    },
    verificaChassi() {
      const veiculoImportado = this.verificaVeiculoImportado();
      const grupoPropostaImportado = this.verificaGrupoPropostaImportado();
      const { idProposta, observacao } = this.dadosProposta;
      const grupoImportadoExiste = this.separarGrupoImportado();
      grupoImportadoExiste &&
        this.adicionaRemoveAvisoAtecaoAoGrupo({
          veiculoImportado,
          grupoPropostaImportado,
          idProposta,
          observacao,
        });
    },
    adicionaRemoveAvisoAtecaoAoGrupo(dados) {
      const {
        veiculoImportado,
        grupoPropostaImportado,
        idProposta,
        observacao,
      } = dados;
      const frase1 = ' Atenção! Veículo importado com grupo nacional.';
      const frase2 = ' Atenção! Veículo nacional com grupo importado.';
      let novaObervacao = observacao;
      if (
        veiculoImportado &&
        !grupoPropostaImportado &&
        this.verificaObservacaoPossuiFrase(observacao, frase1) === -1
      ) {
        novaObervacao = observacao ? observacao + frase1 : frase1;
      }

      if (
        !veiculoImportado &&
        grupoPropostaImportado &&
        this.verificaObservacaoPossuiFrase(observacao, frase2) === -1
      ) {
        novaObervacao = observacao ? observacao + frase2 : frase2;
      }

      if (veiculoImportado && grupoPropostaImportado) {
        novaObervacao = this.removeFraseDaObservacao(observacao, frase1);
        novaObervacao = this.removeFraseDaObservacao(observacao, frase2);
      }
      observacao !== novaObervacao &&
        this.atualizaObservacao(idProposta, novaObervacao);
    },
    verificaObservacaoPossuiFrase(observacao, frase) {
      return observacao ? observacao.indexOf(frase) : -1;
    },
    removeFraseDaObservacao(observacao, frase) {
      const posicaoInicioFraseRemovida = this.verificaObservacaoPossuiFrase(
        observacao,
        frase,
      );
      //a função indexOf retorná a posição na qual começa a frase a ser removida, caso não exista, retornará -1
      if (posicaoInicioFraseRemovida !== -1) {
        const posicaoFimFraseRemovida =
          posicaoInicioFraseRemovida + frase.length;

        const novaObservacao =
          observacao.substring(0, posicaoInicioFraseRemovida) +
          observacao.substring(posicaoFimFraseRemovida);

        this.dadosProposta.observacao = novaObservacao;
        return novaObservacao;
      } else {
        return observacao;
      }
    },
    async atualizaObservacao(idProposta, observacao) {
      await this.requsicaoPost(
        {
          idProposta,
          observacao,
        },
        'Grupo',
        'atualiza-tabela-proposta',
      );
    },
    async buscaGrupos() {
      const res = await service.getAll('Grupo', {}, 'list/' + this.idEmpresa);

      this.grupos = res.data.filter((grupo) => grupo.deletedAt === null);
    },
    isSomenteAgregado: function () {
      return this.dadosProposta.somenteAgregado ? true : false;
    },
    resetPlacaSubstituicao() {
      this.form.placaSubstituicao = '';
      this.modalPlacaSubstituicao = false;
    },
    resetFormCpfCnpj() {
      this.form.dadosPessoais.cpfDuplicado = false;
    },
    validaCpfUnificacao() {
      this.form.dadosPessoais.cpfDuplicado = false;
      this.carregando = true;
      this.formVerificaCpf.idEmpresa = this.dadosProposta.idEmpresa;
      this.formVerificaCpf.cpf = this.form.dadosPessoais.cpfcnpj;
      service
        .post(
          'Associado',
          'verificaCpfAssociadoDuplicado',
          this.formVerificaCpf,
        )
        .then((res) => {
          if (
            this.dadosProposta.dadosCliente.idProspect !=
              res.data.retorno[0].id_prospect ||
            res.data.retorno.length > 1
          ) {
            if (
              res.data.error == 'CPF Duplicado' &&
              !this.form.dadosPessoais.unificaAssociado
            ) {
              this.form.dadosPessoais.cpfDuplicado = true;
            }
            if (
              res.data.error == 'CPF Duplicado' &&
              this.form.dadosPessoais.unificaAssociado == true
            ) {
              this.form.dadosPessoais.cpfDuplicado = false;
            }
          }
          this.carregando = false;
        })
        .catch((e) => {
          this.carregando = false;
          throw new Error(e);
        });
    },
    aceiteUnificarAssociado() {
      this.form.dadosPessoais.unificaAssociado = true;
      this.onSubmit();
    },
    unificarAssociados() {
      this.formUnificacao.cpf = this.cpf || this.form.dadosPessoais.cpfcnpj;
      this.formUnificacao.idEmpresa = this.dadosProposta.idEmpresa;

      this.carregando = true;
      service
        .post('admBTX', 'unificarAssociados', this.formUnificacao)
        .then(() => {
          this.carregando = false;
          this.form.dadosPessoais.unificaAssociado = false;
          this.$bvToast.toast('Cadastro Unificado com Sucesso', {
            title: 'Atenção!',
            solid: true,
            variant: 'success',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
        })
        .catch((e) => {
          this.carregando = false;
          this.form.dadosPessoais.unificaAssociado = false;
          this.$bvToast.toast(e, {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
        });
    },
  },
  components: {
    topoInterno,
    'v-select': vSelect,
    MenuLateral,
    cartaoCredito,
    botaoCamera,
    botaoGaleria,
    botaoVideo,
    FormComplemento,
  },
  async created() {
    await this.buscaDadosToken();
    await this.buscaDadosCotacao();
  },
  mounted() {
    this.getIP();
    this.buscaFotos();
    this.$validator.localize('en', locale);
    window.addEventListener('resize', this.getSize);
    this.getLocation();
    this.buscaGrupos();
    this.buscarDicionarioChassisNacionais();
    this.restringeDatasMensalidade();
  },
};
</script>
<style lang="scss" scoped>
.badge {
  font-size: 100%;
}
.NovaCotacao {
  padding-bottom: 80px;
  label,
  label.d-block,
  .form-group {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    font-family: 'robotobold';
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
video {
  width: 100%;
}
#fileInput {
  display: none;
}
.iconeErro {
  color: red;
  font-size: 56px;
  margin: 20px 0;
}
.iconeSucesso {
  color: green;
  font-size: 56px;
  margin: 20px 0;
}
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}
.btn {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.topoForm {
  border-radius: 10px;
  border-left: 10px solid rgb(52, 138, 167);
  padding: 10px 20px;
  -webkit-box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  margin: 10px;
  text-align: center;
  color: rgb(52, 138, 167);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.conteudoInterno .listaerros {
  font-size: 12px;
}

.img img,
.arquivo,
.assinatura {
  width: 100%;
  border: 1px solid #e7e7e7;
}

.divAssinatura {
  position: relative;
}
.guiaAssinatura {
  border-top: solid 1px rgb(20, 20, 20);
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 30%;
  padding-top: 10px;
}
.arquivo {
  text-align: center;
  padding: 20px;
}
.arquivo .iconeFile {
  font-size: 26px;
  margin-right: 10px;
}

.img label {
  width: 100%;
  font-weight: normal;
  text-align: center;
}

.img .containericone {
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.75);
  width: 100px;
  display: inline-block;
  border: 1px solid #e7e7e7;
  margin-right: 10px;
  margin-top: 10px;
}

.img .icone {
  font-size: 50px;
  color: #333;
}

.assinatura p {
  text-align: center;
  font-size: 12px;
  margin-top: 25px;
}

.legendaIcone,
.imgReprovada {
  width: 50% !important;
}

.legenda {
  font-size: 12px;
}
.rotated {
  rotate: -90deg;
}

.iconeGirarTela {
  rotate: -90deg;
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 30%;
  font-size: 35px;
  animation: rotation 3s infinite;
}
@keyframes rotation {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@media (orientation: landscape) {
  .iconeGirarTela {
    display: none;
  }
  .botaoLimpar {
    position: fixed;
    z-index: 999;
    bottom: 0;
  }
  .botaoFinalizarAssinatura {
    position: fixed;
    z-index: 999;
    bottom: 0;
    right: 0;
    width: 50%;
  }
  .assinatura {
    top: 0;
    left: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
  }
}
</style>
<style lang="scss" scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
</style>
